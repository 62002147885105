import { graphql } from "gatsby"
import { withTranslation } from "gatsby-plugin-react-i18next"
import React from "react"
import Helmet from "react-helmet"
import styled from "styled-components"
import Container from "../../components/Container"
import ConfirmEmail from "../../components/user/ConfirmEmail"
import CourseOptionsEditor from "../../components/user/CourseOptionsEditor"
import CreateAccountForm from "../../components/user/CreateAccountForm"
import LoginStateContext, {
  withLoginStateContext,
} from "../../contexes/LoginStateContext"
import Layout from "../../templates/Layout"

const StyledWrapper = styled.div`
  background: white;
`
class SignInPage extends React.Component {
  static contextType = LoginStateContext

  state = {
    step: 1,
  }

  onStepComplete = () => {
    this.setState((prevState) => ({
      step: prevState.step + 1,
    }))
    if (typeof window !== "undefined") {
      window.scrollTo(0, 0)
    }
  }

  render() {
    let stepComponent
    if (this.state.step === 1) {
      stepComponent = <CreateAccountForm onComplete={this.onStepComplete} />
    } else if (this.state.step === 2) {
      stepComponent = (
        <CourseOptionsEditor
          courseVariant="nodl"
          onComplete={this.onStepComplete}
        />
      )
    } else {
      stepComponent = <ConfirmEmail onComplete={this.onStepComplete} />
    }

    return (
      <StyledWrapper>
        <Layout>
          <Helmet title={this.props.t("user:createAccount")} />
          <Container>{stepComponent}</Container>
        </Layout>
      </StyledWrapper>
    )
  }
}

export default withTranslation("user")(withLoginStateContext(SignInPage))

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
